import $ from 'jquery';

import '../scss/partials/login.scss';

import Swal from 'sweetalert2';
$(document).ready(function() {
  lemon_login_partial()
  register_login_partial()

})



function lemon_login_partial() {
  $('.lemon-login').off("click");
  $('.lemon-login').on('click',function(e){
    e.preventDefault();
    $('.loading-loader').show();
    var data = {
      action: 'ajax_login_partial_lemon',
    };
    $.ajax({
      type: 'post',
      url: ajax_url,
      data: data,
      success: function (response) {
        
        if (response.success ) {
          
          Swal.fire({
            showClass : {
              popup: 'login swal2-show',
            },
            html: response.modal,
            showCloseButton: true,
            closeButtonHtml : '<i class="icon-cross"></i>',
            showCancelButton: false,
            showConfirmButton: false,
            focusConfirm: false,
            buttonsStyling : false,
          })

          $('.swal2-cancel').on('click',function(e){
            e.preventDefault()
            Swal.close()
          }) 
          lemon_login_partial()
          register_login_partial()
          lemon_login();
          
          
          
        } else {
          

        }
        $('.loading-loader').hide();
      },
      error: function(response) {
        //console.log(data)
        $('.loading-loader').hide();
      }
    }); 
  })
}

function lemon_login() {
  $('.lemon-login-button').on('click',function(e){
    e.preventDefault();

    let isValid = true;
    $('form.login-form .form-control').removeClass('is-invalid');


    $('form.login-form .form-control').each(function() {
      if ($(this).is(":invalid")) {
        $(this).closest('.form-group').find('.invalid-feedback').text($(this).closest('.form-group').find('.invalid-feedback').attr('data-text'))
        $(this).addClass('is-invalid')

        isValid = false;
      }
    });

    if (isValid) {
      let email = $(this).closest('.modal-content').find('form.login-form input[name="email"]').val()
      let pass = $(this).closest('.modal-content').find('form.login-form input[name="password"]').val()
      $('.loading-loader').show();
      var data = {
        action: 'ajax_login_lemon',
        email: email,
        password: pass,
      };
      $.ajax({
        type: 'post',
        url: ajax_url,
        data: data,
        success: function (response) {
          
          if (response.success ) {
            window.location.reload();
            return;
            
            
          } else {

            $('.loading-loader').hide();
            $('form.login-form .invalid-feedback').text(response.message)
            $('form.login-form .form-control').addClass('is-invalid')
          }
          
        },
        error: function(response) {
          //console.log(data)
          $('.loading-loader').hide();
        }
      }); 
    }
  })
}

function register_login_partial() {
  $('.lemon-register').off("click");
  $('.lemon-register').on('click',function(e){
    e.preventDefault();
    $('.loading-loader').show();
    var data = {
      action: 'ajax_register_partial_lemon',
    };
    $.ajax({
      type: 'post',
      url: ajax_url,
      data: data,
      success: function (response) {
        
        if (response.success ) {
          
          Swal.fire({
            showClass : {
              popup: 'login swal2-show',
            },
            html: response.modal,
            showCloseButton: true,
            closeButtonHtml : '<i class="icon-cross"></i>',
            showCancelButton: false,
            showConfirmButton: false,
            focusConfirm: false,
            buttonsStyling : false,
          })

          $('.swal2-cancel').on('click',function(e){
            e.preventDefault()
            Swal.close()
          }) 
          lemon_login_partial()
          register_login_partial()
          lemon_register();
          
          
          
        } else {
          

        }
        $('.loading-loader').hide();
      },
      error: function(response) {
        //console.log(data)
        $('.loading-loader').hide();
      }
    }); 
  })
}


function lemon_register() {
  $('.lemon-register-button').on('click',function(e){
    e.preventDefault();

    let isValid = true;
    $('form.register-form .form-control').removeClass('is-invalid');


    $('form.register-form .form-control').each(function() {
      if ($(this).is(":invalid")) {
        $(this).closest('.form-group').find('.invalid-feedback').text($(this).closest('.form-group').find('.invalid-feedback').attr('data-text'))
        $(this).addClass('is-invalid')

        isValid = false;
      }
    });

    if (isValid) {
      let email = $(this).closest('.modal-content').find('form.register-form input[name="email"]').val()
      let username = $(this).closest('.modal-content').find('form.register-form input[name="username"]').val()
      let pass = $(this).closest('.modal-content').find('form.register-form input[name="password"]').val()
      $('.loading-loader').show();
      var data = {
        action: 'ajax_register_lemon',
        email: email,
        password: pass,
        username: username,
      };
      $.ajax({
        type: 'post',
        url: ajax_url,
        data: data,
        success: function (response) {
          
          if (response.success ) {
            window.location.reload();
            return;
            
            
          } else {

            $('.loading-loader').hide();
            $.each(response,function(key, value){
              $('form.register-form .form-control[name="'+key+'"]').addClass('is-invalid');
              $('form.register-form .form-control[name="'+key+'"] ~ .invalid-feedback').text(value);
            })
          }
          
        },
        error: function(response) {
          //console.log(data)
          $('.loading-loader').hide();
        }
      }); 
    }
  })
}